import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    IconClose,
    IconWarningType
} from 'svgIcons/MotionPortalIcons';
import colors from 'theme/_colors.scss';

import './styles.scss';


const DisclaimerBanner = ({ text = '', disableClose = false }) => {
    const [showDisclaimer, setShowDisclaimer] = useState(true);
    return showDisclaimer ?
        <div className='general-disclaimer-container'>
            <div className='type-icon'>
                <IconWarningType width={24} height={24} color={colors.orangePeel} />
            </div>
            <div className='disclaimer-text'>
                {text}
            </div>
            {!disableClose && <div className='close-icon'>
                <span onClick={() => setShowDisclaimer(false)}>
                    <IconClose
                        color={colors.black}
                        strokeWidth='6'
                    />
                </span>
            </div>}

        </div> : null;
};

DisclaimerBanner.propTypes = {
    text: PropTypes.string
};

export default DisclaimerBanner;
