import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { IconCondition } from 'svgIcons/MotionPortalIcons';

const TimeInput = ({ label, defaultTime, defaultPeriod, className, setTime, setPeriod, errorMessage }) => {
    const [timeValue, setTimeValue] = useState(defaultTime);
    const [periodValue, setPeriodValue] = useState(defaultPeriod);

    const handleTimeChange = (e) => {
        setTimeValue(e.target.value);
        setTime(e.target.value);
    };

    const handlePeriodChange = (e) => {
        setPeriodValue(e.target.value);
        setPeriod(e.target.value);
    };

    return (
        <div className='time-input-container'>
            <label className='label'>{label}</label>
            <div className='flex'>
                <input
                    type='text'
                    value={timeValue}
                    onChange={handleTimeChange}
                    className={className}
                    placeholder='HH:MM'
                />
                {errorMessage &&
                    <div className='error-icon'>
                        <IconCondition condition={3} ></IconCondition>
                    </div>}
                <select
                    value={periodValue}
                    onChange={handlePeriodChange}
                    className='time-select'
                >
                    <option value='AM'>AM</option>
                    <option value='PM'>PM</option>
                </select>
            </div>
            {errorMessage && <div className='error-message'>{errorMessage}</div>}
        </div>
    );
};

TimeInput.propTypes = {
    text: PropTypes.string
};

export default TimeInput;
