import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    IconInfo,
} from 'svgIcons/MotionPortalIcons';


import './styles.scss';


const InfoBanner = ({ text = '' }) => {
    const [showInfo, setShowInfo] = useState(true);
    return showInfo ?
        <div className='general-info-container'>
            <div className='type-icon'>
                <IconInfo width={24} height={24} color={'blue'} />
            </div>
            <div className='info-text'>
                {text}
            </div>
            <div className='close-icon'>
                <span onClick={() => setShowInfo(false)}>
                </span>
            </div>
        </div> : null;
};

InfoBanner.propTypes = {
    text: PropTypes.string
};

export default InfoBanner;
